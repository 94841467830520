import { IUnitGroupListItem } from "../@types/unitGroup";
import { AggregationApiClient } from "./aggregation-client";

export type PerPage = 4 | 12 | 24;
export const DEFAULT_PER_PAGE: PerPage = 12;

export const fetchUnitGroupListByAttributes = ({
  lastId,
  perPage = DEFAULT_PER_PAGE,
  cityId,
  hotelIds,
  attributes = "",
  hotelAttributes = "",
  sortOrder = "asc",
  sortByField,
}: {
  perPage?: PerPage;
  lastId?: string;
  cityId?: number;
  hotelIds?: string;
  attributes?: string;
  hotelAttributes?: string;
  sortOrder?: string;
  sortByField?: string;
}): Promise<IUnitGroupListItem[]> => {
  if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
    return Promise.resolve([]);
  }

  const payload: any = {};

  if (lastId) {
    payload.lastId = lastId;
  }
  if (perPage) {
    payload.perPage = perPage;
  }
  if (cityId != null) {
    payload.cities = cityId.toString();
  }

  if (hotelIds) {
    payload.hotelIds = hotelIds;
  }
  if (attributes) {
    payload.attributes = attributes;
  }
  if (sortOrder) {
    payload.sortOrder = sortOrder;
  }
  if (sortByField) {
    payload.sortByField = sortByField;
  }
  if (hotelAttributes) {
    payload.hotelAttributes = hotelAttributes;
  }

  return AggregationApiClient.HotelUnitGroupService.hotelUnitGroupControllerFindUnitGroups({
    language: "en",
    perPage: 12,
    sortByField: "price",
    ...payload,
  })
    .then((items) => {
      const unitGroups: IUnitGroupListItem[] = items.map((f) => ({
        ...f,
        description: f.description?.replace(/(<([^>]+)>)/gi, "") || "",
        shortDescription: f.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
        link: `/unit/${f.id}`,
      }));
      return unitGroups;
    })
    .catch(() => []);
};
