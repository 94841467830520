import { AggregationApiClient } from "./aggregation-client";

export const fetchAllPropertyAttributes = () => {
  // if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
  //   return Promise.resolve(propertiesDefaultMock);
  // }
  return AggregationApiClient.HotelsService.hotelsControllerFetchHotelAttributes({
    perPage: 99,
    page: 0,
    language: "en",
  })
    .then((items) => {
      return items
        .sort((a, b) => a.priority - b.priority)
        .map((item) => {
          return {
            ...item,
            attributes: item.attributes.sort((a, b) => a.priority - b.priority),
          };
        });
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      return [];
    });
};
